const layerStyles = {
  // #region Orium theme
  'divider-default': {
    borderStyle: 'solid',
    borderColor: 'gray.300',
    borderWidth: '0 0 1px 0',
  },
  'no-scroll-bar': {
    'div:first-of-type::-webkit-scrollbar': {
      display: 'none' /* for Chrome, Safari, and Opera */,
    },
    'div:first-of-type': {
      MsOverflowStyle: 'none' /* for Internet Explorer, Edge */,
      scrollbarWidth: 'none' /* for Firefox */,
    },
  },
  highlight: {
    backgroundColor: 'highlight',
    textColor: 'text',
  },
  dark: {
    backgroundColor: 'text',
    textColor: 'background',
  },
  light: {
    backgroundColor: 'background',
    textColor: 'text',
  },
  'dark-text': {
    textColor: 'background',
  },
  'light-text': {
    textColor: 'text',
  },
  // #endregion

  // #region Spoonflower theme
  cartItemFormat: {
    backgroundColor: 'gray-opacity.800',
    borderRadius: 'md',
    color: 'warm-white',
    paddingX: '3',
    paddingY: '1',
  },
  hero: {
    paddingX: { base: '6', lg: '28' },
    paddingY: { base: '12', lg: '24' },
    width: 'full',
  },
  primary: {
    color: 'primary-text',
  },
  secondary: {
    color: 'secondary-text',
  },
  tertiary: {
    color: 'tertiary-text',
  },
  // #endregion
}

export type LayerStyles = typeof layerStyles

export default layerStyles
